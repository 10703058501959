import { Link } from "react-router-dom"

export const EmailStatus = () => {
    const isEmail=localStorage.getItem("emailExist")==="true"
    return (
        <>
        {!isEmail &&
            <div
            className="w-100 border p-3 text-center bg-danger text-light fs-5"
            >
            Please update your email id..!
            <Link
                to="/changemail"
                >
                Click Here
            </Link>
        </div>}
    </>
    )
}

