import React, { lazy } from "react";
import requiredAuth from "./hoc/requiredAuth";
import ClipLoader from "react-spinners/ClipLoader";

 import "./App.css";
import { Route, Switch } from "react-router-dom";
import { EmailStatus } from "./Component/EmailUpdateMssge/EmailStatus";
const Dashboard = lazy(() => import("./Container/Dashboard/index"));
const Login = lazy(() => import("./Auth/Login"));
const Register = lazy(() => import("./Auth/Register"));
const ForgotPassword = lazy(() => import("./Auth/ForgetPassword"));
const ResetPassword = lazy(() => import("./Auth/ResetPassword"));
const EmailUpdate = lazy(() => import("./Component/EmailUpdateMssge/EmailUpdate"));


function App(props) {

  return (
    <React.Fragment>
      <React.Suspense
        fallback={
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <ClipLoader
              // css={override}
              size={150}
              color={"#123abc"}
              loading={true}
            />
          </div>
        }
      >
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/Register" exact component={Register} />
          <Route path="/forgot_password" exact component={ForgotPassword} />
          <Route path="/reset_password" exact component={ResetPassword} />
          <Route path="/change_mail" exact component={EmailUpdate} />

          <Route path="/" component={requiredAuth(Dashboard)} />
        </Switch>
      </React.Suspense>
    </React.Fragment>
  );
}

export default App;